import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';

import './breadcrumbs.scss';

export const Breadcrumbs = memo(({ className, data, itemClassName, creativeId, olClassName }) => (
  <nav className={classnames('breadcrumb', className)} aria-label="Breadcrumb" data-tracking-parent={creativeId}>
    <ol className={olClassName}>
      {data &&
        data.map(
          ({ title, href, dataTrackingId, attrs }, index) =>
            title && (
              <li
                key={title}
                className={classnames('breadcrumb-item', itemClassName)}
                aria-current={index === data.length - 1 && !href ? 'true' : undefined}
              >
                {href ? (
                  <Link
                    to={href}
                    {...attrs}
                    data-tracking-id={dataTrackingId || 'view_content'}
                    aria-current={index === data.length - 1 ? 'page' : undefined}
                  >
                    {title}
                  </Link>
                ) : (
                  title
                )}
              </li>
            )
        )}
    </ol>
  </nav>
));

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      dataTrackingId: PropTypes.string,
      attrs: PropTypes.shape({}),
    })
  ).isRequired,
  itemClassName: PropTypes.string,
  creativeId: PropTypes.string,
  olClassName: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: '',
  itemClassName: '',
  creativeId: undefined,
  olClassName: 'pb-0_5',
};
